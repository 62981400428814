import "./ServiceDetailModal.scss";
import { useMemo, useState, useEffect } from "react";
import SlidingModal from "../sliding-modal/SlidingModal";
import { IconButton } from "../../../elements/icon-button/IconButton";
import { COLORS } from "../../../models/colors";
import PhotoSlider from "../../photo-slider/PhotoSlider";
import { Badge } from "../../../elements/badge/Badge";
import { Button } from "../../../elements/button/Button";
import { VARIANTS } from "../../../models/variants";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { useCart } from "providers/cart-provider";
import {
  LocationPageServiceFragment,
  ServiceAddonFragment
} from "../../../routes/location/Location.generated";
import {
  IBookAppointmentServiceFull,
  isSelectedService
} from "providers/cart-provider.utils";
import Tooltip from "@mui/material/Tooltip";
import { Toast } from "elements/toast/Toast";
import AddOnItem from "./add-on-item/AddOnItem";

export interface IServiceDetailModalData {
  show: boolean;
  onHide: () => void;
  onAdd: (
    service: LocationPageServiceFragment,
    addOns?: string[],
    isEdit?: boolean
  ) => void;
  personIndex: number;
  service: LocationPageServiceFragment;
  className?: string;
}

const ServiceDetailModal = ({
  show,
  onHide,
  onAdd,
  service,
  className,
  personIndex
}: IServiceDetailModalData): JSX.Element => {
  const { cart } = useCart();
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>(0);
  const [addOnsCart, setAddOnsCart] = useState<string[]>([]);
  const [
    requiredAddOnsCountForValidation,
    setRequiredAddOnsCountForValidation
  ] = useState<string[]>([]);
  const [isFoundAddonsInCart, setIsFoundAddonsInCart] =
    useState<boolean>(false);
  const [warningMsg, setWarningMsg] = useState("");
  const photos = useMemo<string[]>((): string[] => {
    return [
      service.photo_1_url,
      service.photo_2_url,
      service.photo_3_url,
      service.photo_4_url,
      service.photo_5_url
    ].filter((photo) => !!photo) as string[];
  }, [service]);

  const serviceWithChoiceIsRequired = service?.add_ons?.filter(
    (item) => item.core_service.choice_is_required === true
  )?.length;

  const closeToastAndReset = () => {
    setWarningMsg("");
  };

  useEffect(() => {
    if (!addOnsCart.length && !requiredAddOnsCountForValidation.length) {
      const foundAddons = cart.services.find(
        (item) =>
          item.service.id === service.id && item.personIndex === personIndex
      )?.addOns;
      if (!!foundAddons) {
        setAddOnsCart(foundAddons);
        setRequiredAddOnsCountForValidation(
          service?.add_ons
            ?.filter((item) => item.core_service.choice_is_required === true)
            ?.map((item) => item.core_service.id)
        );
        setIsFoundAddonsInCart(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.services]);

  useEffect(() => {
    if (
      warningMsg &&
      requiredAddOnsCountForValidation.length === serviceWithChoiceIsRequired
    ) {
      closeToastAndReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredAddOnsCountForValidation.length, serviceWithChoiceIsRequired]);

  const BottomButton = (): JSX.Element => {
    const canNotBeSelectedManicureTwice = !!cart.services?.find(
      (cartService: IBookAppointmentServiceFull) =>
        cartService.personIndex === personIndex &&
        service.category === "MANICURE" &&
        cartService.service.category === "MANICURE"
    );
    const canNotBeSelectedPedicureTwice = !!cart.services?.find(
      (cartService: IBookAppointmentServiceFull) =>
        cartService.personIndex === personIndex &&
        service.category === "PEDICURE" &&
        cartService.service.category === "PEDICURE"
    );
    const isServiceAdded = isSelectedService(cart, service.id, personIndex);
    const isDisabled =
      !isServiceAdded &&
      (canNotBeSelectedManicureTwice || canNotBeSelectedPedicureTwice);

    const sortedAddOnsFromRealCart = cart.services
      .find(
        (item) =>
          item.service.id === service.id && item.personIndex === personIndex
      )
      ?.addOns?.slice()
      .sort();

    const sortedAddOnsState = addOnsCart?.slice().sort();

    const isEdit =
      isFoundAddonsInCart &&
      JSON.stringify(sortedAddOnsFromRealCart) !==
        JSON.stringify(sortedAddOnsState);
    return (
      <Tooltip
        placement="top"
        title={
          isDisabled
            ? `You can't choose ${service.category?.toLocaleLowerCase()} twice`
            : ""
        }
      >
        <div>
          {warningMsg && (
            <Toast
              msg={warningMsg}
              type="warning_with_error_icon"
              showIcon
              onClose={closeToastAndReset}
              className="mb-[85px] lg:mb-[0px]"
            />
          )}
          <div className="add-button-wrapper">
            <div className="add-buttons">
              <Button
                color={isServiceAdded ? COLORS.SECONDARY : COLORS.PRIMARY}
                variant={isServiceAdded ? VARIANTS.OUTLINED : VARIANTS.FILLED}
                text={
                  isServiceAdded
                    ? isEdit
                      ? "Remove"
                      : "Remove from Pointment"
                    : "Add to Pointment"
                }
                iconLeftName={isServiceAdded ? "minus" : "plus"}
                className={`add-button flexible ${
                  isDisabled || !!warningMsg
                    ? "opacity-[0.3] !cursor-not-allowed"
                    : ""
                }`}
                disabled={isDisabled || !!warningMsg}
                onClick={() => {
                  if (
                    requiredAddOnsCountForValidation.length !==
                    serviceWithChoiceIsRequired
                  ) {
                    setWarningMsg("Please choose an option “Yes” or “No”");
                    return;
                  }
                  onAdd(service, addOnsCart);
                  onHide();
                }}
              />
              {isEdit && (
                <Button
                  color={COLORS.PRIMARY}
                  variant={VARIANTS.FILLED}
                  text={"Update"}
                  className={`add-button flexible ${
                    isDisabled || !!warningMsg
                      ? "opacity-[0.3] !cursor-not-allowed"
                      : ""
                  }`}
                  disabled={isDisabled || !!warningMsg}
                  onClick={() => {
                    if (
                      requiredAddOnsCountForValidation.length !==
                      serviceWithChoiceIsRequired
                    ) {
                      setWarningMsg("Please choose an option “Yes” or “No”");
                      return;
                    }
                    onAdd(service, addOnsCart, true);
                    onHide();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Tooltip>
    );
  };

  return (
    <SlidingModal
      show={show}
      onHide={onHide}
      disableHeader={photos.length > 0}
      BottomControls={BottomButton}
      className={className}
    >
      <div className="service-detail-container hide-scroll-bar">
        {photos.length > 0 && (
          <div className="photo-slider-container">
            <PhotoSlider
              photos={photos}
              className="photo-slider-wrapper"
              onSlide={setCurrentPhotoIndex}
            />
            <Badge
              color={COLORS.SECONDARY}
              value={`${currentPhotoIndex + 1}/${photos.length}`}
              className="counter-badge"
            />
            <Badge
              color={COLORS.SECONDARY}
              value={service.name}
              className="formatted-name-badge"
            />
            <IconButton
              color={COLORS.PRIMARY}
              iconName="close"
              className="close-button"
              onClick={onHide}
            />
          </div>
        )}
        <div className="service-detail-wrapper">
          <div className="info">
            <div className="title">{service.name}</div>
            <div className="price-duration">
              ${service.price} • {service.duration} mins
            </div>
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: service.description || "" }}
          />
          <hr />
          {service.special_conditions &&
            service.special_conditions.length > 0 && (
              <>
                <div className="special-conditions">
                  <div>
                    <SvgIcon name="alert" />
                  </div>
                  <div className="lines-ellipsis">
                    {/*TODO: Insert ExpandableText component*/}
                    <span className="font-bold">Special conditions.</span>{" "}
                    <span>{service.special_conditions}</span>
                  </div>
                </div>
                <hr />
              </>
            )}
          {serviceWithChoiceIsRequired > 0 && (
            <div>
              <div className="title">Do you need to add?</div>
              {service.add_ons
                .filter((item) => item.core_service.choice_is_required === true)
                .map((addOn: ServiceAddonFragment) => {
                  if (
                    addOn.core_service &&
                    addOn.core_service.id &&
                    addOn.core_service.name &&
                    addOn.core_service.duration !== null &&
                    addOn.core_service.duration !== undefined
                  ) {
                    return (
                      <AddOnItem
                        key={addOn.id}
                        addOnId={addOn.core_service.id}
                        name={addOn.core_service.name}
                        duration={addOn.core_service.duration}
                        price={addOn.core_service.price}
                        description={addOn?.core_service?.description}
                        choiceIsRequired={addOn.core_service.choice_is_required}
                        addOnsCart={addOnsCart}
                        setAddOnsCart={setAddOnsCart}
                        isFoundAddonsInCart={isFoundAddonsInCart}
                        requiredAddOnsCountForValidation={
                          requiredAddOnsCountForValidation
                        }
                        setRequiredAddOnsCountForValidation={
                          setRequiredAddOnsCountForValidation
                        }
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          )}
          {service?.add_ons?.filter(
            (item) => item.core_service.choice_is_required !== true
          )?.length > 0 && (
            <div>
              <div
                className={`title ${
                  serviceWithChoiceIsRequired > 0 ? "mt-6" : ""
                }`}
              >
                Add-ons
              </div>
              {service.add_ons
                .filter((item) => item.core_service.choice_is_required !== true)
                .map((addOn: ServiceAddonFragment) => {
                  if (
                    addOn.core_service &&
                    addOn.core_service.id &&
                    addOn.core_service.name &&
                    addOn.core_service.duration !== null &&
                    addOn.core_service.duration !== undefined
                  ) {
                    return (
                      <AddOnItem
                        key={addOn.id}
                        addOnId={addOn.core_service.id}
                        name={addOn.core_service.name}
                        duration={addOn.core_service.duration}
                        price={addOn?.core_service?.price || 0}
                        description={addOn?.core_service?.description}
                        choiceIsRequired={addOn.core_service.choice_is_required}
                        addOnsCart={addOnsCart}
                        setAddOnsCart={setAddOnsCart}
                        isFoundAddonsInCart={isFoundAddonsInCart}
                        requiredAddOnsCountForValidation={
                          requiredAddOnsCountForValidation
                        }
                        setRequiredAddOnsCountForValidation={
                          setRequiredAddOnsCountForValidation
                        }
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              <div className="w-full h-[150px]" />
            </div>
          )}
        </div>
      </div>
    </SlidingModal>
  );
};

export default ServiceDetailModal;
