import "./Toast.scss";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";
import { SvgIcon } from "elements/svg-icon/svg-icon";

type TToastType =
  | "success"
  | "error"
  | "warning"
  | "info"
  | "warning_with_error_icon";

interface IToastProps {
  msg: string;
  cyId?: string;
  type: TToastType;
  showIcon?: boolean;
  className?: string;
  rounded?: boolean;
  onClose?: () => void;
}

const baseClassName = "toast";
const bem = bemElement(baseClassName);

const getIconName = (type: TToastType): string => {
  switch (type) {
    case "success":
      return "checkmark";
    case "error":
      return "alert";
    case "warning":
      return "time";
    case "info":
      return "info";
    case "warning_with_error_icon":
      return "alert";
    default:
      return "";
  }
};

export const Toast = ({
  className = "",
  cyId = "",
  msg,
  type,
  showIcon,
  rounded,
  onClose
}: IToastProps): JSX.Element => {
  return (
    <div
      className={joinClassNames(
        bemModifier(
          baseClassName,
          type === "warning_with_error_icon" ? "warning" : type,
          {
            rounded: rounded
          }
        ),
        className
      )}
    >
      <p className={bem("content")}>
        {showIcon && (
          <SvgIcon className={bem("icon")} name={getIconName(type)} />
        )}
        <span className={bem("message")} data-cy={cyId}>
          {msg}
        </span>
      </p>
      {onClose && (
        <div className={bem("close-button")} aria-hidden onClick={onClose}>
          <SvgIcon name="close" />
        </div>
      )}
    </div>
  );
};
