/* eslint-disable max-len */
import "./DateAndTimeSelect.scss";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { motion, useAnimation } from "framer-motion";
import { useCart } from "providers/cart-provider";
import { getRelativeDate, getTime } from "utils/date-time";
import { useDebounce } from "react-use";
import { IPerson } from "interfaces";
import { IBookAppointmentServiceFull } from "providers/cart-provider.utils";
import { startOfDay, endOfDay, addMinutes, isSameDay } from "date-fns";
import { TArtist, TLocation, TPossibleAppointment, TService } from "types";
import { useAxios } from "providers/axios";
import { addToCache, getCachedData } from "../../utils/cacheHelper";
import { format } from "date-fns";
import { schedulingBufferMinutes } from "utils/constants";
import Skeleton from "@mui/material/Skeleton";
import { useUser } from "providers/user";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export interface IDateAndTimeSelectData {
  startTimes: string[];
  rescheduleAppointment?: any;
}

interface IFormattedDateAndTime {
  formattedDate: string;
  formattedTimes: Array<{ time: string; startTime: string }>;
  dateToProcess?: string;
}

interface IDateAndStartTime {
  date: string;
  startTime: string;
}

interface ISingleDayFetchBody {
  startTime: string;
  endTime: string;
  locations: string[];
  persons: IPerson[];
  constraints?: string;
  reschedule_appointment_id?: string;
  isConcurrent?: boolean;
}

// This variable is to check if other request is already sent
let currentActiveDateIndex = null;

const DateAndTimeSelect = ({
  startTimes,
  rescheduleAppointment
}: IDateAndTimeSelectData) => {
  const {
    selectTime,
    cart,
    possibleAppointment,
    availableTimes,
    setPossibleAppointment,
    setArtists,
    setBookingAsUnavailable
  } = useCart();
  const { api } = useAxios();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  // Memoizing formattedDatesAndTimes
  const formattedDatesAndTimes = useMemo<IFormattedDateAndTime[]>(() => {
    // Initialize an empty array to collect start times.
    let collectedStartTimes: any = [];
    // Loop to collect dates for the next 30 days.
    for (let i = 0; i <= 90; i++) {
      let dateToProcess = new Date();
      dateToProcess.setDate(dateToProcess.getDate() + i);
      // adding the calculated date into the collectedStartTimes array.
      collectedStartTimes.push(dateToProcess);
    }
    // Combine the calculated dates with additional start times provided by props from parent component,
    // this prop is changed/modified automatically every time when "setPossibleAppointment" is invoked.
    collectedStartTimes = [...collectedStartTimes, ...startTimes];

    // Initialize an empty array to collect result that is needed for return
    const result: IFormattedDateAndTime[] = [];

    // loop through the collected start times and convert them into object with 2 keys:
    // "date": formatted dates (for example Today, Tomorrow, Sun 10 Dec)
    // and also as key "startTime" I have unformatted date.
    // unformatted date can be string that was returned form API (it means I also have times for slots)
    // or date instance (type object) generated by me in loop to collect dates for next 30 days (it means that I don't have times)
    collectedStartTimes
      .map(
        (date: string): IDateAndStartTime => ({
          date: getRelativeDate(date, cart.location?.timezone),
          startTime: date
        })
      )
      .forEach((dateAndTime: IDateAndStartTime) => {
        // Finding index with formattedDate if I already have element with this date in result array
        const index = result.findIndex(
          (element: IFormattedDateAndTime) =>
            element.formattedDate === dateAndTime.date
        );
        // next 2 variables I need to check if I have data in cache object
        const dateForFormat = new Date(dateAndTime.startTime);
        // I am formatting to yyy-MM-dd format because I don't need hours and minutes because it will never be the same
        const formattedDateToProcess = format(dateForFormat, "yyyy-MM-dd");
        // Checking if I have data in cache object
        const dataFromCache = getCachedData(
          `${cart.location?.id}-${formattedDateToProcess}`
        );
        // if I did not find element in result array, I am adding it in this if statement
        if (index === -1) {
          result.push({
            // Today, Tomorrow, Sun 10 Dec
            formattedDate: dateAndTime.date,
            // I know for sure if "startTime" is a date instance it is generated by me
            // so I know that I don't have times before I fetch data for this date.
            // Thats why in advance I am adding "Unavailable" string. I am using this "Unavailable"
            // for loading state but main reason is if nothing is fetched from API (really unavailable)
            // then I am not triggering this memoized function and just using pre assigned "Unavailable" string.

            // if date in not date instance it means in string I have time too (because it is fetched from API)
            // this strings looks like this: "2023-12-20T06:00:00.000Z", "2023-12-20T06:15:00.000Z", "2023-12-20T06:30:00.000Z"
            // in this example I have 15 min slots and I am getting start time from "getTime" function ("10:00 AM", 10:15 AM, 10:30 AM)
            formattedTimes:
              typeof dateAndTime.startTime === "object"
                ? [
                    {
                      time: "Unavailable",
                      startTime: dateAndTime.startTime
                    }
                  ]
                : [
                    {
                      time: getTime(
                        dateAndTime.startTime,
                        cart.location?.timezone
                      ),
                      startTime: dateAndTime.startTime
                    }
                  ],
            //I use "dateToProcess" to fetch data from API; if "dateToProcess" equals undefined, it means that
            // I already have information on that date and fetching is not needed.
            // If I have a date instance, it means that I need to fetch data, but first, I will check cache object;
            // if I have data on this date in the cache and it is processed, I don't need to fetch it again or set new state,
            // that's why I am setting undefined
            dateToProcess:
              typeof dateAndTime.startTime === "object"
                ? dataFromCache?.isProcessed
                  ? undefined
                  : dateAndTime.startTime
                : undefined
          });
        } else {
          if (result[index]?.formattedTimes?.[0]?.time === "Unavailable") {
            // If I find an index in the result array, it means I don't need the "Unavailable" slot anymore
            result[index]?.formattedTimes.shift();
          }
          // I am adding (pushing) new time slots (objects) in the results array on a found date.
          result[index]?.formattedTimes.push({
            time: getTime(dateAndTime.startTime, cart.location?.timezone),
            startTime: dateAndTime.startTime
          });
          // if I found it I don't need to process (fetch from API) this date
          result[index].dateToProcess = undefined;
        }
      });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimes]);

  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(0);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number>(0);
  const [startTouchPosition, setStartTouchPosition] = useState<number>(0);
  const startDragPosition = useRef<number>(0);
  const [dragStarted, setDragStarted] = useState<boolean>(false);
  const [clickTime, setClickTime] = useState<number>(0);
  const dateAnimation = useAnimation();
  const timeAnimation = useAnimation();
  const preventScroll = useCallback((e: WheelEvent) => {
    e.preventDefault();
    e.stopPropagation();

    return false;
  }, []);

  useEffect(() => {
    setSelectedDateIndex(0);
    setSelectedTimeIndex(0);
    dateAnimation.start({ transform: `translateY(0px)` }).catch();
    timeAnimation.start({ transform: `translateY(0px)` }).catch();
  }, [dateAnimation, timeAnimation]);

  function getSingleDayFetchBody(dateToProcess: string): ISingleDayFetchBody {
    const timezone = cart?.location?.timezone || "PST8PDT";

    const processedDateInSalonTz = utcToZonedTime(dateToProcess, timezone);

    const nowInSalonTz = utcToZonedTime(new Date(), timezone);

    const isTodayInSalonTz = isSameDay(processedDateInSalonTz, nowInSalonTz);

    let startTime: string;

    if (isTodayInSalonTz) {
      const bufferMinutes = schedulingBufferMinutes(!!user?.impersonate_name);
      const adjustedNowInSalonTz = addMinutes(nowInSalonTz, bufferMinutes);

      const adjustedNowUtc = zonedTimeToUtc(adjustedNowInSalonTz, timezone);
      startTime = adjustedNowUtc.toISOString();
    } else {
      const startOfDayInSalonTz = startOfDay(processedDateInSalonTz);

      const startOfDayUtc = zonedTimeToUtc(startOfDayInSalonTz, timezone);
      startTime = startOfDayUtc.toISOString();
    }

    const endOfDayInSalonTz = endOfDay(processedDateInSalonTz);
    const endOfDayUtc = zonedTimeToUtc(endOfDayInSalonTz, timezone);
    const endTime = endOfDayUtc.toISOString();

    const persons: IPerson[] = [];

    cart.services?.forEach((service: IBookAppointmentServiceFull) => {
      const isPedicure = service.service.category === "PEDICURE";

      if (!persons[service.personIndex]) {
        persons[service.personIndex] = {
          services: [],
          service_with_addons: []
        };
      }

      if (isPedicure) {
        persons[service.personIndex].services.unshift(service.service.id);
        persons?.[service.personIndex]?.service_with_addons?.unshift({
          service_id: service.service.id,
          addons_ids: service.addOns || [],
          personIndex: service.personIndex
        });
      } else {
        persons[service.personIndex].services.push(service.service.id);
        persons?.[service.personIndex]?.service_with_addons?.push({
          service_id: service.service.id,
          addons_ids: service.addOns || [],
          personIndex: service.personIndex
        });
      }
    });

    return {
      locations: cart.location?.id ? [cart.location.id] : [],
      startTime,
      endTime,
      persons,
      isConcurrent: !!cart?.isConcurrent
    };
  }

  const fetchSingleDayPermutations = async (
    dateToProcess: string,
    savedDateIndex: number
  ) => {
    // this helps to check if other date request is already sent
    currentActiveDateIndex = savedDateIndex;

    const date = new Date(dateToProcess);
    const formattedDate = format(date, "yyyy-MM-dd");
    // first I am checking if I have response in cache
    const cachedData = getCachedData(`${cart.location?.id}-${formattedDate}`);
    if (cachedData?.isProcessed) {
      // if I found data in cache and it is processed, I am just turning off loader and leaving this function without fetching anything
      // I don't even need to return response from cache because I already have correct data in memoized formattedDatesAndTimes
      setIsLoading(false);
      // if array of appointments is empty, it booking is not available
      if (cachedData?.appointments?.length === 0) {
        setBookingAsUnavailable();
      }
      return;
    }

    if (cachedData && !cachedData?.isProcessed) {
      // if I found data in cache and it is not processed, I am setting new possible appointment state
      // that is triggering memoized formattedDatesAndTimes.
      if (cachedData?.appointments?.length > 0) {
        setPossibleAppointment((prev) => [
          ...(prev as TPossibleAppointment[]),
          ...cachedData?.appointments
        ]);
        // I don't need to set location and services because they will not change on date and time screen
        // but I am setting artists because, maybe I have 2 or even 0 artists but after fetching some date
        // I may have new 5 artists.
        setArtists((prev) => [...(prev as TArtist[]), ...cachedData?.artists]);
      } else {
        // I need it because, memoized formattedDatesAndTimes is not triggered and I need to know that this date does not needs processing anymore.
        formattedDatesAndTimes[savedDateIndex].dateToProcess = undefined;
        setBookingAsUnavailable();
      }

      // Once I process this date I change the cache for this date, saying it is processed and will not need processing anymore
      addToCache(
        `${cart.location?.id}-${formattedDate}`,
        cachedData?.appointments,
        cachedData?.artists,
        true
      );
      setIsLoading(false);
      setTimeout(() => {
        setSelectedDateIndex(savedDateIndex);
        setSelectedTimeIndex(0);
        dateAnimation
          .start({ transform: `translateY(${-40 * savedDateIndex}px)` })
          .catch();
        timeAnimation.start({ transform: `translateY(0px)` }).catch();
      }, 0);
      return;
    }

    const body = {
      ...getSingleDayFetchBody(dateToProcess),
      locations: rescheduleAppointment?.location?.id
        ? [rescheduleAppointment?.location?.id]
        : getSingleDayFetchBody(dateToProcess).locations
    };
    try {
      setIsLoading(true);
      const response: {
        appointments: TPossibleAppointment[];
        location: TLocation;
        artists: TArtist[];
        services: TService[];
      } = (await api
        .post(`/v1/default-permutations/`, body)
        .then((r) => r.data[0])) as {
        appointments: TPossibleAppointment[];
        location: TLocation;
        artists: TArtist[];
        services: TService[];
      };

      if (savedDateIndex !== currentActiveDateIndex) {
        // if user moved to other date while was fetching other date data, I am saving response in cache
        // with flag isProcessed === false  if a user will return to this date, only the state will change
        // with data from the cache, and fetching will not be needed
        addToCache(
          `${cart.location?.id}-${formattedDate}`,
          response?.appointments,
          response?.artists,
          false
        );
        return;
      } else {
        // If a user waited, I am adding the flag isProcessed === true to not process it again
        addToCache(
          `${cart.location?.id}-${formattedDate}`,
          response?.appointments,
          [],
          true
        );
      }
      if (response?.appointments?.length > 0) {
        // this is triggering memoized formattedDatesAndTimes
        setPossibleAppointment((prev) => [
          ...(prev as TPossibleAppointment[]),
          ...response.appointments
        ]);
        // I don't need to set location and services because they will not change on date and time screen
        // but I am setting artists because, maybe I have 2 or even 0 artists but after fetching some date
        // I may have new 5 artists.
        setArtists((prev) => [...(prev as TArtist[]), ...response?.artists]);
      } else {
        // I needed it because , memoized formattedDatesAndTimes is not triggered and I need to know that this date does not needs processing anymore.
        formattedDatesAndTimes[savedDateIndex].dateToProcess = undefined;
        setBookingAsUnavailable();
      }

      setIsLoading(false);
      // I need setTimeout to ensure this actions happen after the current call stack is cleared,
      // everything that is inside setTimeout needs to be invoked lastly in call stack, so 0 sec delay is enough.
      setTimeout(() => {
        setSelectedDateIndex(savedDateIndex);
        setSelectedTimeIndex(0);
        dateAnimation
          .start({ transform: `translateY(${-40 * savedDateIndex}px)` })
          .catch();
        timeAnimation.start({ transform: `translateY(0px)` }).catch();
      }, 0);
    } catch (err) {}
  };

  useDebounce(
    () => {
      const selectedDate: string =
        formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes[
          selectedTimeIndex
        ]?.startTime;
      if (
        formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes[
          selectedTimeIndex
        ]?.time !== "Unavailable"
      ) {
        selectTime(selectedDate);
      }
      // if (axiosCancelToken) {
      // if I am going to fetch some other new date data while I was already fetching (pending) older data
      // I am cancelling old API request. because if I will not cancel,  old request's response will unnecessary trigger memoized formattedDatesAndTimes
      //   axiosCancelToken.cancel("Cancelled the previous request");
      // }
      let dateToProcess =
        formattedDatesAndTimes?.[selectedDateIndex]?.dateToProcess;
      if (dateToProcess) {
        // if I have date to process I call this function that will handle
        // fetch, cancel or use response from cache.
        fetchSingleDayPermutations(dateToProcess, selectedDateIndex);
      } else {
        // this helps to check if other date request is already sent
        currentActiveDateIndex = selectedDateIndex;
        // if I don't need to fetch I am turning off loader because loader is
        // turned on immediately on scroll or date click for not showing fake "Unavailable" text.
        // I am not turning on loader in debounce because then
        // I need to wait 0.5 sec. but for turning off it is a great place.
        setIsLoading(false);
        if (
          formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes[
            selectedTimeIndex
          ]?.time === "Unavailable"
        ) {
          setBookingAsUnavailable();
        }
      }
    },
    500,
    [selectedDateIndex, selectedTimeIndex, possibleAppointment]
  );

  useEffect(() => {
    setSelectedTimeIndex(0);
    timeAnimation.start({ transform: `translateY(0px)` }).catch();
    if (formattedDatesAndTimes?.[selectedDateIndex]?.dateToProcess) {
      // turning on loader immediately, without waiting to debounce delay
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateIndex, timeAnimation]);

  useEffect(() => {
    if (
      formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes[
        selectedTimeIndex
      ]?.startTime !== cart.start_time
    ) {
      setSelectedDateIndex(0);
      setSelectedTimeIndex(0);
      dateAnimation.start({ transform: `translateY(0px)` }).catch();
      timeAnimation.start({ transform: `translateY(0px)` }).catch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.start_time]);

  const getDateIndexByAvailableTimes = (direction?: "up" | "down"): number => {
    const filteredTimes = formattedDatesAndTimes
      .filter((date) =>
        availableTimes?.some((time) =>
          date.formattedTimes.some((timeslot) => timeslot.startTime === time)
        )
      )
      .filter((date) => {
        if (direction === undefined) {
          return true;
        }

        const currentStartTime =
          formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes[0]
            .startTime;
        if (direction === "down") {
          return (
            new Date(date.formattedTimes[0].startTime) >
            new Date(currentStartTime)
          );
        } else {
          return (
            new Date(date.formattedTimes[0].startTime) <
            new Date(currentStartTime)
          );
        }
      });

    if (filteredTimes.length > 0) {
      return formattedDatesAndTimes.findIndex(
        (timeslot) =>
          timeslot ===
          (direction === "down" || direction === undefined
            ? filteredTimes[0]
            : filteredTimes[filteredTimes.length - 1])
      );
    }

    return -1;
  };

  const getTimeIndexByAvailableTimes = (direction?: "up" | "down"): number => {
    const filteredTimes = formattedDatesAndTimes[
      selectedDateIndex
    ].formattedTimes
      .filter((timeslot) => availableTimes?.includes(timeslot.startTime))
      .filter((timeslot) => {
        if (direction === undefined) {
          return true;
        }

        const currentStartTime =
          formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes[
            selectedTimeIndex
          ].startTime;
        if (direction === "down") {
          return new Date(timeslot.startTime) > new Date(currentStartTime);
        } else {
          return new Date(timeslot.startTime) < new Date(currentStartTime);
        }
      });

    if (filteredTimes.length > 0) {
      return formattedDatesAndTimes?.[
        selectedDateIndex
      ]?.formattedTimes.findIndex(
        (timeslot) =>
          timeslot.startTime ===
          (direction === "down" || direction === undefined
            ? filteredTimes[0]
            : filteredTimes[filteredTimes.length - 1]
          ).startTime
      );
    }

    return -1;
  };

  const isAvailableDate = (dateIndex: number): boolean => {
    if (formattedDatesAndTimes?.[dateIndex]?.dateToProcess) {
      // if date needs processing it means I don't know if main artist can do service on that date,
      // so I can not have line through in UI on a date that is not processed.
      return true;
    }

    return !!availableTimes?.some((time) =>
      formattedDatesAndTimes[dateIndex]?.formattedTimes.some(
        (timeslot) => timeslot.startTime === time
      )
    );
  };

  const isAvailableTime = (timeIndex: number): boolean => {
    // We don't need line through on Unavailable text. Does not meter if date is processed or not,
    // if we have "Unavailable" in formattedTimes array it is always on 0 index
    if (
      formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes?.[0]?.time ===
      "Unavailable"
    ) {
      return true;
    }

    return !!availableTimes?.includes(
      formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes[timeIndex]
        .startTime
    );
  };

  useEffect(() => {
    if (
      availableTimes?.length &&
      availableTimes?.length > 0 &&
      !isAvailableTime(selectedTimeIndex)
    ) {
      const foundIndex = getTimeIndexByAvailableTimes();

      if (foundIndex !== -1) {
        setSelectedTimeIndex(foundIndex);
        timeAnimation
          .start({ transform: `translateY(${-40 * foundIndex}px)` })
          .catch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTimes?.length, selectedTimeIndex, timeAnimation]);

  useEffect(() => {
    if (
      availableTimes?.length &&
      availableTimes?.length > 0 &&
      !isAvailableDate(selectedDateIndex)
    ) {
      const foundIndex = getDateIndexByAvailableTimes();

      if (foundIndex !== -1) {
        setSelectedDateIndex(foundIndex);
        dateAnimation
          .start({ transform: `translateY(${-40 * foundIndex}px)` })
          .catch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTimes?.length, dateAnimation, selectedDateIndex]);

  const dateWheelHandler = (event: React.WheelEvent<HTMLDivElement>) => {
    if (
      (event.deltaY < 0 && selectedDateIndex === 0) ||
      (event.deltaY > 0 &&
        selectedDateIndex === formattedDatesAndTimes.length - 1)
    ) {
      return;
    }
    if (
      availableTimes?.length !== 0 &&
      !isAvailableDate(selectedDateIndex + (event.deltaY > 0 ? 1 : -1))
    ) {
      const foundIndex = getDateIndexByAvailableTimes(
        event.deltaY > 0 ? "down" : "up"
      );

      if (foundIndex !== -1) {
        setSelectedDateIndex(foundIndex);
        dateAnimation
          .start({ transform: `translateY(${-40 * foundIndex}px)` })
          .catch();
      }
    } else {
      dateAnimation
        .start({
          transform: `translateY(${
            -40 * (selectedDateIndex + (event.deltaY > 0 ? 1 : -1))
          }px)`
        })
        .catch();
      setSelectedDateIndex(
        (prevState: number) => prevState + (event.deltaY > 0 ? 1 : -1)
      );
    }
  };

  const timeWheelHandler = (event: React.WheelEvent<HTMLDivElement>) => {
    if (
      (event.deltaY < 0 && selectedTimeIndex === 0) ||
      (event.deltaY > 0 &&
        selectedTimeIndex ===
          formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes.length -
            1)
    ) {
      return;
    }
    if (
      availableTimes?.length !== 0 &&
      !isAvailableTime(selectedTimeIndex + (event.deltaY > 0 ? 1 : -1))
    ) {
      const foundIndex = getTimeIndexByAvailableTimes(
        event.deltaY > 0 ? "down" : "up"
      );

      if (foundIndex !== -1) {
        setSelectedTimeIndex(foundIndex);
        timeAnimation
          .start({ transform: `translateY(${-40 * foundIndex}px)` })
          .catch();
      }
    } else {
      timeAnimation
        .start({
          transform: `translateY(${
            -40 * (selectedTimeIndex + (event.deltaY > 0 ? 1 : -1))
          }px)`
        })
        .catch();
      setSelectedTimeIndex(
        (prevState: number) => prevState + (event.deltaY > 0 ? 1 : -1)
      );
    }
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setStartTouchPosition(event.touches[0].clientY);
  };

  const handleDateTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    dateAnimation
      .start({
        transform: `translateY(${
          -40 * selectedDateIndex +
          event.touches[0].clientY -
          startTouchPosition
        }px)`
      })
      .catch();
  };

  const handleTimeTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    timeAnimation
      .start({
        transform: `translateY(${
          -40 * selectedTimeIndex +
          event.touches[0].clientY -
          startTouchPosition
        }px)`
      })
      .catch();
  };

  const getIndex = (
    startYPosition: number,
    currentYPosition: number,
    prevIndex: number,
    maxIndex: number
  ): number => {
    const offset = -40 * prevIndex + currentYPosition - startYPosition;
    let currentIndex = -Math.round(offset / 40);
    if (currentIndex > maxIndex) {
      currentIndex = maxIndex;
    } else if (currentIndex < 0) {
      currentIndex = 0;
    }

    return currentIndex;
  };

  const handleDateTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    const currentIndex = getIndex(
      startTouchPosition,
      event.changedTouches[0].clientY,
      selectedDateIndex,
      formattedDatesAndTimes.length - 1
    );
    if (availableTimes?.length === 0 || isAvailableDate(currentIndex)) {
      dateAnimation
        .start({ transform: `translateY(${-40 * currentIndex}px)` })
        .catch();
      setSelectedDateIndex(currentIndex);
    } else {
      const foundIndex = getDateIndexByAvailableTimes(
        startTouchPosition - event.changedTouches[0].clientY > 0 ? "down" : "up"
      );

      dateAnimation
        .start({
          transform: `translateY(${
            -40 * (foundIndex !== -1 ? foundIndex : selectedDateIndex)
          }px)`
        })
        .catch();
      setSelectedDateIndex(foundIndex !== -1 ? foundIndex : selectedDateIndex);
    }
  };

  const handleTimeTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    const currentIndex = getIndex(
      startTouchPosition,
      event.changedTouches[0].clientY,
      selectedTimeIndex,
      formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes.length - 1
    );
    if (availableTimes?.length === 0 || isAvailableTime(currentIndex)) {
      timeAnimation
        .start({ transform: `translateY(${-40 * currentIndex}px)` })
        .catch();
      setSelectedTimeIndex(currentIndex);
    } else {
      const foundIndex = getTimeIndexByAvailableTimes(
        startTouchPosition - event.changedTouches[0].clientY > 0 ? "down" : "up"
      );

      timeAnimation
        .start({
          transform: `translateY(${
            -40 * (foundIndex !== -1 ? foundIndex : selectedTimeIndex)
          }px)`
        })
        .catch();
      setSelectedTimeIndex(foundIndex !== -1 ? foundIndex : selectedTimeIndex);
    }
  };

  const handleDateMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    startDragPosition.current = event.clientY;
    setDragStarted(true);
    setClickTime(new Date().getTime());

    document.addEventListener("mouseup", handleDateMouseUp, { once: true });
  };

  const handleTimeMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    startDragPosition.current = event.clientY;
    setDragStarted(true);
    setClickTime(new Date().getTime());

    document.addEventListener("mouseup", handleTimeMouseUp, { once: true });
  };

  const handleDateMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (dragStarted) {
      dateAnimation
        .start({
          transform: `translateY(${
            -40 * selectedDateIndex + event.clientY - startDragPosition.current
          }px)`
        })
        .catch();
    }
  };

  const handleTimeMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (dragStarted) {
      timeAnimation
        .start({
          transform: `translateY(${
            -40 * selectedTimeIndex + event.clientY - startDragPosition.current
          }px)`
        })
        .catch();
    }
  };

  const handleDateMouseUp = (event: MouseEvent) => {
    const currentIndex = getIndex(
      startDragPosition.current,
      event.clientY,
      selectedDateIndex,
      formattedDatesAndTimes.length - 1
    );
    if (availableTimes?.length === 0 || isAvailableDate(currentIndex)) {
      dateAnimation
        .start({ transform: `translateY(${-40 * currentIndex}px)` })
        .catch();
      setSelectedDateIndex(currentIndex);
    } else {
      const foundIndex = getDateIndexByAvailableTimes(
        startDragPosition.current - event.clientY > 0 ? "down" : "up"
      );

      dateAnimation
        .start({
          transform: `translateY(${
            -40 * (foundIndex !== -1 ? foundIndex : selectedDateIndex)
          }px)`
        })
        .catch();
      setSelectedDateIndex(foundIndex !== -1 ? foundIndex : selectedDateIndex);
    }

    setDragStarted(false);
    setClickTime((prevState) => new Date().getTime() - prevState);
  };

  const handleTimeMouseUp = (event: MouseEvent) => {
    const currentIndex = getIndex(
      startDragPosition.current,
      event.clientY,
      selectedTimeIndex,
      formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes.length - 1
    );
    if (availableTimes?.length === 0 || isAvailableTime(currentIndex)) {
      timeAnimation
        .start({ transform: `translateY(${-40 * currentIndex}px)` })
        .catch();
      setSelectedTimeIndex(currentIndex);
    } else {
      const foundIndex = getTimeIndexByAvailableTimes(
        startDragPosition.current - event.clientY > 0 ? "down" : "up"
      );

      timeAnimation
        .start({
          transform: `translateY(${
            -40 * (foundIndex !== -1 ? foundIndex : selectedTimeIndex)
          }px)`
        })
        .catch();
      setSelectedTimeIndex(foundIndex !== -1 ? foundIndex : selectedTimeIndex);
    }

    setDragStarted(false);
    setClickTime((prevState) => new Date().getTime() - prevState);
  };

  const handleDateClick = (index: number) => {
    if (
      clickTime < 150 &&
      (availableTimes?.length === 0 || isAvailableDate(index))
    ) {
      setSelectedDateIndex(index);
      dateAnimation
        .start({ transform: `translateY(${-40 * index}px)` })
        .catch();
    }
  };

  const handleTimeClick = (index: number) => {
    if (
      clickTime < 150 &&
      (availableTimes?.length === 0 || isAvailableTime(index))
    ) {
      setSelectedTimeIndex(index);
      timeAnimation
        .start({ transform: `translateY(${-40 * index}px)` })
        .catch();
    }
  };

  return (
    <div className="date-and-time-select-container">
      <div className="date-and-time-select-wrapper">
        <motion.div
          className="left-side"
          animate={dateAnimation}
          onWheel={dateWheelHandler}
          onTouchStart={handleTouchStart}
          onTouchMove={handleDateTouchMove}
          onTouchEnd={handleDateTouchEnd}
          onMouseDown={handleDateMouseDown}
          onMouseMove={handleDateMouseMove}
          onMouseEnter={() =>
            document.addEventListener("wheel", preventScroll, {
              passive: false
            })
          }
          onMouseLeave={() =>
            document.removeEventListener("wheel", preventScroll)
          }
        >
          {formattedDatesAndTimes.map(
            (dateAndTimes: IFormattedDateAndTime, i: number) => (
              <div
                aria-hidden
                key={dateAndTimes.formattedDate}
                className={`item${selectedDateIndex === i ? " active" : ""}
                ${
                  availableTimes?.length === 0 || isAvailableDate(i)
                    ? ""
                    : " unavailable"
                }`}
                onClick={() => handleDateClick(i)}
              >
                {dateAndTimes.formattedDate}
              </div>
            )
          )}
        </motion.div>
        <motion.div
          className="right-side"
          animate={timeAnimation}
          onWheel={timeWheelHandler}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTimeTouchMove}
          onTouchEnd={handleTimeTouchEnd}
          onMouseDown={handleTimeMouseDown}
          onMouseMove={handleTimeMouseMove}
          onMouseEnter={() =>
            document.addEventListener("wheel", preventScroll, {
              passive: false
            })
          }
          onMouseLeave={() =>
            document.removeEventListener("wheel", preventScroll)
          }
        >
          {formattedDatesAndTimes?.[selectedDateIndex]?.formattedTimes.map(
            (item: { time: string }, i: number) => (
              <div
                aria-hidden
                key={item.time}
                className={`item${selectedTimeIndex === i ? " active" : ""}
                ${
                  availableTimes?.length === 0 || isAvailableTime(i)
                    ? ""
                    : " unavailable"
                } ${isLoading ? "loadingTransparentText" : ""}`}
                onClick={() => handleTimeClick(i)}
                data-cy={`time-slot-${i}`}
              >
                {i === 0 && (
                  <div
                    className={`loadingSkeleton ${
                      isLoading ? "loadingActive" : ""
                    }`}
                  >
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: "12px" }}
                      width={90}
                      height={24}
                    />
                  </div>
                )}
                {item.time}
              </div>
            )
          )}
        </motion.div>
      </div>
      <div className="select-line" />
      <div className="top-fade-out" />
      <div className="bottom-fade-out" />
    </div>
  );
};

export default DateAndTimeSelect;
