import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type LocationQueryVariables = Types.Exact<{
  where: Types.LocationWhereUniqueInput;
  user_id?: Types.InputMaybe<Types.UuidFilter>;
}>;

export type LocationQuery = {
  __typename?: "Query";
  location?: {
    __typename?: "Location";
    id: string;
    cover_photo_url?: string | null;
    name: string;
    address_display?: string | null;
    address_latitude?: number | null;
    address_longitude?: number | null;
    amenities_enums: Array<Types.AmenityEnum>;
    facebook_url?: string | null;
    instagram_url?: string | null;
    twitter_url?: string | null;
    website_url?: string | null;
    slug?: string | null;
    status: string;
    description?: string | null;
    multi_person?: number | null;
    phone_number?: string | null;
    monday_hours_open?: any | null;
    monday_hours_close?: any | null;
    tuesday_hours_open?: any | null;
    tuesday_hours_close?: any | null;
    wednesday_hours_open?: any | null;
    wednesday_hours_close?: any | null;
    thursday_hours_open?: any | null;
    thursday_hours_close?: any | null;
    friday_hours_open?: any | null;
    friday_hours_close?: any | null;
    saturday_hours_open?: any | null;
    saturday_hours_close?: any | null;
    sunday_hours_open?: any | null;
    sunday_hours_close?: any | null;
    timezone: string;
    menu?: {
      __typename?: "Menu";
      id: string;
      menu_sections: Array<{
        __typename?: "MenuSection";
        id: string;
        name: string;
        menu_section_services: Array<{
          __typename?: "MenuSectionService";
          service?: {
            __typename?: "Service";
            id: string;
            description?: string | null;
            duration?: number | null;
            name: string;
            photo_1_url?: string | null;
            photo_2_url?: string | null;
            photo_3_url?: string | null;
            photo_4_url?: string | null;
            photo_5_url?: string | null;
            price: number;
            special_conditions?: string | null;
            category: Types.ServiceCategory;
            add_ons: Array<{
              __typename?: "ServiceAddOn";
              id: string;
              core_service: {
                __typename?: "Service";
                id: string;
                description?: string | null;
                duration?: number | null;
                name: string;
                photo_1_url?: string | null;
                photo_2_url?: string | null;
                photo_3_url?: string | null;
                photo_4_url?: string | null;
                photo_5_url?: string | null;
                price: number;
                special_conditions?: string | null;
                scheduling_priority?: Types.SchedulingPriority | null;
                choice_is_required: boolean;
              };
              add_on: {
                __typename?: "Service";
                id: string;
                description?: string | null;
                duration?: number | null;
                name: string;
                photo_1_url?: string | null;
                photo_2_url?: string | null;
                photo_3_url?: string | null;
                photo_4_url?: string | null;
                photo_5_url?: string | null;
                price: number;
                special_conditions?: string | null;
              };
            }>;
            core_services: Array<{
              __typename?: "ServiceAddOn";
              id: string;
              core_service: {
                __typename?: "Service";
                id: string;
                description?: string | null;
                duration?: number | null;
                name: string;
                photo_1_url?: string | null;
                photo_2_url?: string | null;
                photo_3_url?: string | null;
                photo_4_url?: string | null;
                photo_5_url?: string | null;
                price: number;
                special_conditions?: string | null;
              };
            }>;
          } | null;
        }>;
      }>;
    } | null;
    company: {
      __typename?: "Company";
      id: string;
      name: string;
      logo_url?: string | null;
      slug?: string | null;
      payment_type: Types.PaymentType;
      late_cancellation_hours: number;
      late_cancellation_charge: number;
      no_show_charge: number;
      is_verified: boolean;
      website_url?: string | null;
    };
    availability: Array<{
      __typename?: "Availability";
      id: string;
      status: string;
      start_time: any;
    }>;
    amenities: Array<{
      __typename?: "LocationAmenity";
      amenity: {
        __typename?: "Amenity";
        id: string;
        type?: string | null;
        name: string;
      };
    }>;
    favorited_by_users: Array<{
      __typename?: "UserFavoriteLocation";
      id: string;
    }>;
  } | null;
};

export type LocationPageLocationFragment = {
  __typename?: "Location";
  id: string;
  cover_photo_url?: string | null;
  name: string;
  address_display?: string | null;
  address_latitude?: number | null;
  address_longitude?: number | null;
  amenities_enums: Array<Types.AmenityEnum>;
  facebook_url?: string | null;
  instagram_url?: string | null;
  twitter_url?: string | null;
  website_url?: string | null;
  slug?: string | null;
  status: string;
  description?: string | null;
  multi_person?: number | null;
  phone_number?: string | null;
  monday_hours_open?: any | null;
  monday_hours_close?: any | null;
  tuesday_hours_open?: any | null;
  tuesday_hours_close?: any | null;
  wednesday_hours_open?: any | null;
  wednesday_hours_close?: any | null;
  thursday_hours_open?: any | null;
  thursday_hours_close?: any | null;
  friday_hours_open?: any | null;
  friday_hours_close?: any | null;
  saturday_hours_open?: any | null;
  saturday_hours_close?: any | null;
  sunday_hours_open?: any | null;
  sunday_hours_close?: any | null;
  timezone: string;
  menu?: {
    __typename?: "Menu";
    id: string;
    menu_sections: Array<{
      __typename?: "MenuSection";
      id: string;
      name: string;
      menu_section_services: Array<{
        __typename?: "MenuSectionService";
        service?: {
          __typename?: "Service";
          id: string;
          description?: string | null;
          duration?: number | null;
          name: string;
          photo_1_url?: string | null;
          photo_2_url?: string | null;
          photo_3_url?: string | null;
          photo_4_url?: string | null;
          photo_5_url?: string | null;
          price: number;
          special_conditions?: string | null;
          category: Types.ServiceCategory;
          add_ons: Array<{
            __typename?: "ServiceAddOn";
            id: string;
            core_service: {
              __typename?: "Service";
              id: string;
              description?: string | null;
              duration?: number | null;
              name: string;
              photo_1_url?: string | null;
              photo_2_url?: string | null;
              photo_3_url?: string | null;
              photo_4_url?: string | null;
              photo_5_url?: string | null;
              price: number;
              special_conditions?: string | null;
              scheduling_priority?: Types.SchedulingPriority | null;
              choice_is_required: boolean;
            };
            add_on: {
              __typename?: "Service";
              id: string;
              description?: string | null;
              duration?: number | null;
              name: string;
              photo_1_url?: string | null;
              photo_2_url?: string | null;
              photo_3_url?: string | null;
              photo_4_url?: string | null;
              photo_5_url?: string | null;
              price: number;
              special_conditions?: string | null;
            };
          }>;
          core_services: Array<{
            __typename?: "ServiceAddOn";
            id: string;
            core_service: {
              __typename?: "Service";
              id: string;
              description?: string | null;
              duration?: number | null;
              name: string;
              photo_1_url?: string | null;
              photo_2_url?: string | null;
              photo_3_url?: string | null;
              photo_4_url?: string | null;
              photo_5_url?: string | null;
              price: number;
              special_conditions?: string | null;
            };
          }>;
        } | null;
      }>;
    }>;
  } | null;
  company: {
    __typename?: "Company";
    id: string;
    name: string;
    logo_url?: string | null;
    slug?: string | null;
    payment_type: Types.PaymentType;
    late_cancellation_hours: number;
    late_cancellation_charge: number;
    no_show_charge: number;
    is_verified: boolean;
    website_url?: string | null;
  };
  availability: Array<{
    __typename?: "Availability";
    id: string;
    status: string;
    start_time: any;
  }>;
  amenities: Array<{
    __typename?: "LocationAmenity";
    amenity: {
      __typename?: "Amenity";
      id: string;
      type?: string | null;
      name: string;
    };
  }>;
  favorited_by_users: Array<{
    __typename?: "UserFavoriteLocation";
    id: string;
  }>;
};

export type LocationPageAvailabilityFragment = {
  __typename?: "Availability";
  id: string;
  status: string;
  start_time: any;
};

export type MenuSectionsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.MenuSectionWhereInput>;
}>;

export type MenuSectionsQuery = {
  __typename?: "Query";
  menuSections: Array<{
    __typename?: "MenuSection";
    id: string;
    name: string;
    menu_section_services: Array<{
      __typename?: "MenuSectionService";
      service?: {
        __typename?: "Service";
        id: string;
        description?: string | null;
        duration?: number | null;
        name: string;
        photo_1_url?: string | null;
        photo_2_url?: string | null;
        photo_3_url?: string | null;
        photo_4_url?: string | null;
        photo_5_url?: string | null;
        price: number;
        special_conditions?: string | null;
        category: Types.ServiceCategory;
        add_ons: Array<{
          __typename?: "ServiceAddOn";
          id: string;
          core_service: {
            __typename?: "Service";
            id: string;
            description?: string | null;
            duration?: number | null;
            name: string;
            photo_1_url?: string | null;
            photo_2_url?: string | null;
            photo_3_url?: string | null;
            photo_4_url?: string | null;
            photo_5_url?: string | null;
            price: number;
            special_conditions?: string | null;
            scheduling_priority?: Types.SchedulingPriority | null;
            choice_is_required: boolean;
          };
          add_on: {
            __typename?: "Service";
            id: string;
            description?: string | null;
            duration?: number | null;
            name: string;
            photo_1_url?: string | null;
            photo_2_url?: string | null;
            photo_3_url?: string | null;
            photo_4_url?: string | null;
            photo_5_url?: string | null;
            price: number;
            special_conditions?: string | null;
          };
        }>;
        core_services: Array<{
          __typename?: "ServiceAddOn";
          id: string;
          core_service: {
            __typename?: "Service";
            id: string;
            description?: string | null;
            duration?: number | null;
            name: string;
            photo_1_url?: string | null;
            photo_2_url?: string | null;
            photo_3_url?: string | null;
            photo_4_url?: string | null;
            photo_5_url?: string | null;
            price: number;
            special_conditions?: string | null;
          };
        }>;
      } | null;
    }>;
  }>;
};

export type LocationPageMenuSectionFragment = {
  __typename?: "MenuSection";
  id: string;
  name: string;
  menu_section_services: Array<{
    __typename?: "MenuSectionService";
    service?: {
      __typename?: "Service";
      id: string;
      description?: string | null;
      duration?: number | null;
      name: string;
      photo_1_url?: string | null;
      photo_2_url?: string | null;
      photo_3_url?: string | null;
      photo_4_url?: string | null;
      photo_5_url?: string | null;
      price: number;
      special_conditions?: string | null;
      category: Types.ServiceCategory;
      add_ons: Array<{
        __typename?: "ServiceAddOn";
        id: string;
        core_service: {
          __typename?: "Service";
          id: string;
          description?: string | null;
          duration?: number | null;
          name: string;
          photo_1_url?: string | null;
          photo_2_url?: string | null;
          photo_3_url?: string | null;
          photo_4_url?: string | null;
          photo_5_url?: string | null;
          price: number;
          special_conditions?: string | null;
          scheduling_priority?: Types.SchedulingPriority | null;
          choice_is_required: boolean;
        };
        add_on: {
          __typename?: "Service";
          id: string;
          description?: string | null;
          duration?: number | null;
          name: string;
          photo_1_url?: string | null;
          photo_2_url?: string | null;
          photo_3_url?: string | null;
          photo_4_url?: string | null;
          photo_5_url?: string | null;
          price: number;
          special_conditions?: string | null;
        };
      }>;
      core_services: Array<{
        __typename?: "ServiceAddOn";
        id: string;
        core_service: {
          __typename?: "Service";
          id: string;
          description?: string | null;
          duration?: number | null;
          name: string;
          photo_1_url?: string | null;
          photo_2_url?: string | null;
          photo_3_url?: string | null;
          photo_4_url?: string | null;
          photo_5_url?: string | null;
          price: number;
          special_conditions?: string | null;
        };
      }>;
    } | null;
  }>;
};

export type LocationPageMenuSectionServiceFragment = {
  __typename?: "MenuSectionService";
  service?: {
    __typename?: "Service";
    id: string;
    description?: string | null;
    duration?: number | null;
    name: string;
    photo_1_url?: string | null;
    photo_2_url?: string | null;
    photo_3_url?: string | null;
    photo_4_url?: string | null;
    photo_5_url?: string | null;
    price: number;
    special_conditions?: string | null;
    category: Types.ServiceCategory;
    add_ons: Array<{
      __typename?: "ServiceAddOn";
      id: string;
      core_service: {
        __typename?: "Service";
        id: string;
        description?: string | null;
        duration?: number | null;
        name: string;
        photo_1_url?: string | null;
        photo_2_url?: string | null;
        photo_3_url?: string | null;
        photo_4_url?: string | null;
        photo_5_url?: string | null;
        price: number;
        special_conditions?: string | null;
        scheduling_priority?: Types.SchedulingPriority | null;
        choice_is_required: boolean;
      };
      add_on: {
        __typename?: "Service";
        id: string;
        description?: string | null;
        duration?: number | null;
        name: string;
        photo_1_url?: string | null;
        photo_2_url?: string | null;
        photo_3_url?: string | null;
        photo_4_url?: string | null;
        photo_5_url?: string | null;
        price: number;
        special_conditions?: string | null;
      };
    }>;
    core_services: Array<{
      __typename?: "ServiceAddOn";
      id: string;
      core_service: {
        __typename?: "Service";
        id: string;
        description?: string | null;
        duration?: number | null;
        name: string;
        photo_1_url?: string | null;
        photo_2_url?: string | null;
        photo_3_url?: string | null;
        photo_4_url?: string | null;
        photo_5_url?: string | null;
        price: number;
        special_conditions?: string | null;
      };
    }>;
  } | null;
};

export type LocationPageServiceFragment = {
  __typename?: "Service";
  id: string;
  description?: string | null;
  duration?: number | null;
  name: string;
  photo_1_url?: string | null;
  photo_2_url?: string | null;
  photo_3_url?: string | null;
  photo_4_url?: string | null;
  photo_5_url?: string | null;
  price: number;
  special_conditions?: string | null;
  category: Types.ServiceCategory;
  add_ons: Array<{
    __typename?: "ServiceAddOn";
    id: string;
    core_service: {
      __typename?: "Service";
      id: string;
      description?: string | null;
      duration?: number | null;
      name: string;
      photo_1_url?: string | null;
      photo_2_url?: string | null;
      photo_3_url?: string | null;
      photo_4_url?: string | null;
      photo_5_url?: string | null;
      price: number;
      special_conditions?: string | null;
      scheduling_priority?: Types.SchedulingPriority | null;
      choice_is_required: boolean;
    };
    add_on: {
      __typename?: "Service";
      id: string;
      description?: string | null;
      duration?: number | null;
      name: string;
      photo_1_url?: string | null;
      photo_2_url?: string | null;
      photo_3_url?: string | null;
      photo_4_url?: string | null;
      photo_5_url?: string | null;
      price: number;
      special_conditions?: string | null;
    };
  }>;
  core_services: Array<{
    __typename?: "ServiceAddOn";
    id: string;
    core_service: {
      __typename?: "Service";
      id: string;
      description?: string | null;
      duration?: number | null;
      name: string;
      photo_1_url?: string | null;
      photo_2_url?: string | null;
      photo_3_url?: string | null;
      photo_4_url?: string | null;
      photo_5_url?: string | null;
      price: number;
      special_conditions?: string | null;
    };
  }>;
};

export type ServiceAddonFragment = {
  __typename?: "ServiceAddOn";
  id: string;
  core_service: {
    __typename?: "Service";
    id: string;
    description?: string | null;
    duration?: number | null;
    name: string;
    photo_1_url?: string | null;
    photo_2_url?: string | null;
    photo_3_url?: string | null;
    photo_4_url?: string | null;
    photo_5_url?: string | null;
    price: number;
    special_conditions?: string | null;
    scheduling_priority?: Types.SchedulingPriority | null;
    choice_is_required: boolean;
  };
  add_on: {
    __typename?: "Service";
    id: string;
    description?: string | null;
    duration?: number | null;
    name: string;
    photo_1_url?: string | null;
    photo_2_url?: string | null;
    photo_3_url?: string | null;
    photo_4_url?: string | null;
    photo_5_url?: string | null;
    price: number;
    special_conditions?: string | null;
  };
};

export type ServiceCoreServiceFragment = {
  __typename?: "ServiceAddOn";
  id: string;
  core_service: {
    __typename?: "Service";
    id: string;
    description?: string | null;
    duration?: number | null;
    name: string;
    photo_1_url?: string | null;
    photo_2_url?: string | null;
    photo_3_url?: string | null;
    photo_4_url?: string | null;
    photo_5_url?: string | null;
    price: number;
    special_conditions?: string | null;
  };
};

export type AddLocationToFavoriteMutationVariables = Types.Exact<{
  data: Types.UserFavoriteLocationCreateInput;
}>;

export type AddLocationToFavoriteMutation = {
  __typename?: "Mutation";
  createOneUserFavoriteLocation: {
    __typename?: "UserFavoriteLocation";
    id: string;
  };
};

export type RemoveLocationFromFavoriteMutationVariables = Types.Exact<{
  where: Types.UserFavoriteLocationWhereUniqueInput;
}>;

export type RemoveLocationFromFavoriteMutation = {
  __typename?: "Mutation";
  deleteOneUserFavoriteLocation?: {
    __typename?: "UserFavoriteLocation";
    id: string;
  } | null;
};

export const ServiceAddonFragmentDoc = gql`
  fragment ServiceAddon on ServiceAddOn {
    id
    core_service {
      id
      description
      duration
      name
      photo_1_url
      photo_2_url
      photo_3_url
      photo_4_url
      photo_5_url
      price
      special_conditions
      scheduling_priority
      choice_is_required
    }
    add_on {
      id
      description
      duration
      name
      photo_1_url
      photo_2_url
      photo_3_url
      photo_4_url
      photo_5_url
      price
      special_conditions
    }
  }
`;
export const ServiceCoreServiceFragmentDoc = gql`
  fragment ServiceCoreService on ServiceAddOn {
    id
    core_service {
      id
      description
      duration
      name
      photo_1_url
      photo_2_url
      photo_3_url
      photo_4_url
      photo_5_url
      price
      special_conditions
    }
  }
`;
export const LocationPageServiceFragmentDoc = gql`
  fragment LocationPageService on Service {
    id
    description
    duration
    name
    photo_1_url
    photo_2_url
    photo_3_url
    photo_4_url
    photo_5_url
    price
    special_conditions
    category
    add_ons {
      ...ServiceAddon
    }
    core_services {
      ...ServiceCoreService
    }
  }
  ${ServiceAddonFragmentDoc}
  ${ServiceCoreServiceFragmentDoc}
`;
export const LocationPageMenuSectionFragmentDoc = gql`
  fragment LocationPageMenuSection on MenuSection {
    id
    name
    menu_section_services {
      service {
        ...LocationPageService
      }
    }
  }
  ${LocationPageServiceFragmentDoc}
`;
export const LocationPageAvailabilityFragmentDoc = gql`
  fragment LocationPageAvailability on Availability {
    id
    status
    start_time
  }
`;
export const LocationPageLocationFragmentDoc = gql`
  fragment LocationPageLocation on Location {
    id
    cover_photo_url
    name
    address_display
    address_latitude
    address_longitude
    amenities_enums
    facebook_url
    instagram_url
    twitter_url
    website_url
    slug
    status
    description
    multi_person
    phone_number
    monday_hours_open
    monday_hours_close
    tuesday_hours_open
    tuesday_hours_close
    wednesday_hours_open
    wednesday_hours_close
    thursday_hours_open
    thursday_hours_close
    friday_hours_open
    friday_hours_close
    saturday_hours_open
    saturday_hours_close
    sunday_hours_open
    sunday_hours_close
    timezone
    menu {
      id
      menu_sections {
        ...LocationPageMenuSection
      }
    }
    company {
      id
      name
      logo_url
      slug
      payment_type
      late_cancellation_hours
      late_cancellation_charge
      no_show_charge
      is_verified
      website_url
    }
    availability {
      ...LocationPageAvailability
    }
    amenities {
      amenity {
        id
        type
        name
      }
    }
    favorited_by_users(where: { user_id: $user_id }) {
      id
    }
  }
  ${LocationPageMenuSectionFragmentDoc}
  ${LocationPageAvailabilityFragmentDoc}
`;
export const LocationPageMenuSectionServiceFragmentDoc = gql`
  fragment LocationPageMenuSectionService on MenuSectionService {
    service {
      ...LocationPageService
    }
  }
  ${LocationPageServiceFragmentDoc}
`;
export const LocationDocument = gql`
  query Location($where: LocationWhereUniqueInput!, $user_id: UuidFilter) {
    location(where: $where) {
      ...LocationPageLocation
    }
  }
  ${LocationPageLocationFragmentDoc}
`;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      where: // value for 'where'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useLocationQuery(
  baseOptions: Apollo.QueryHookOptions<LocationQuery, LocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationQuery, LocationQueryVariables>(
    LocationDocument,
    options
  );
}
export function useLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationQuery,
    LocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationQuery, LocationQueryVariables>(
    LocationDocument,
    options
  );
}
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<
  typeof useLocationLazyQuery
>;
export type LocationQueryResult = Apollo.QueryResult<
  LocationQuery,
  LocationQueryVariables
>;
export const MenuSectionsDocument = gql`
  query MenuSections($where: MenuSectionWhereInput) {
    menuSections(where: $where) {
      ...LocationPageMenuSection
    }
  }
  ${LocationPageMenuSectionFragmentDoc}
`;

/**
 * __useMenuSectionsQuery__
 *
 * To run a query within a React component, call `useMenuSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuSectionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMenuSectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MenuSectionsQuery,
    MenuSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MenuSectionsQuery, MenuSectionsQueryVariables>(
    MenuSectionsDocument,
    options
  );
}
export function useMenuSectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MenuSectionsQuery,
    MenuSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MenuSectionsQuery, MenuSectionsQueryVariables>(
    MenuSectionsDocument,
    options
  );
}
export type MenuSectionsQueryHookResult = ReturnType<
  typeof useMenuSectionsQuery
>;
export type MenuSectionsLazyQueryHookResult = ReturnType<
  typeof useMenuSectionsLazyQuery
>;
export type MenuSectionsQueryResult = Apollo.QueryResult<
  MenuSectionsQuery,
  MenuSectionsQueryVariables
>;
export const AddLocationToFavoriteDocument = gql`
  mutation AddLocationToFavorite($data: UserFavoriteLocationCreateInput!) {
    createOneUserFavoriteLocation(data: $data) {
      id
    }
  }
`;
export type AddLocationToFavoriteMutationFn = Apollo.MutationFunction<
  AddLocationToFavoriteMutation,
  AddLocationToFavoriteMutationVariables
>;

/**
 * __useAddLocationToFavoriteMutation__
 *
 * To run a mutation, you first call `useAddLocationToFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLocationToFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLocationToFavoriteMutation, { data, loading, error }] = useAddLocationToFavoriteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddLocationToFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLocationToFavoriteMutation,
    AddLocationToFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddLocationToFavoriteMutation,
    AddLocationToFavoriteMutationVariables
  >(AddLocationToFavoriteDocument, options);
}
export type AddLocationToFavoriteMutationHookResult = ReturnType<
  typeof useAddLocationToFavoriteMutation
>;
export type AddLocationToFavoriteMutationResult =
  Apollo.MutationResult<AddLocationToFavoriteMutation>;
export type AddLocationToFavoriteMutationOptions = Apollo.BaseMutationOptions<
  AddLocationToFavoriteMutation,
  AddLocationToFavoriteMutationVariables
>;
export const RemoveLocationFromFavoriteDocument = gql`
  mutation RemoveLocationFromFavorite(
    $where: UserFavoriteLocationWhereUniqueInput!
  ) {
    deleteOneUserFavoriteLocation(where: $where) {
      id
    }
  }
`;
export type RemoveLocationFromFavoriteMutationFn = Apollo.MutationFunction<
  RemoveLocationFromFavoriteMutation,
  RemoveLocationFromFavoriteMutationVariables
>;

/**
 * __useRemoveLocationFromFavoriteMutation__
 *
 * To run a mutation, you first call `useRemoveLocationFromFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLocationFromFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLocationFromFavoriteMutation, { data, loading, error }] = useRemoveLocationFromFavoriteMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveLocationFromFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveLocationFromFavoriteMutation,
    RemoveLocationFromFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveLocationFromFavoriteMutation,
    RemoveLocationFromFavoriteMutationVariables
  >(RemoveLocationFromFavoriteDocument, options);
}
export type RemoveLocationFromFavoriteMutationHookResult = ReturnType<
  typeof useRemoveLocationFromFavoriteMutation
>;
export type RemoveLocationFromFavoriteMutationResult =
  Apollo.MutationResult<RemoveLocationFromFavoriteMutation>;
export type RemoveLocationFromFavoriteMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveLocationFromFavoriteMutation,
    RemoveLocationFromFavoriteMutationVariables
  >;
