import {
  TLocation,
  TPossibleAppointmentArtist,
  TPossibleAppointmentDefaultArtists,
  TService
} from "types";

export interface IBookAppointmentServiceFull {
  artist?: TPossibleAppointmentDefaultArtists;
  personIndex: number;
  service: TService;
  start_time?: string;
  end_time?: string;
  addOns?: string[];
}

export interface IBookAppointmentFull {
  start_time: string;
  location: TLocation | null;
  services: IBookAppointmentServiceFull[];
  payment_method_id?: string;
  bookingUnavailable?: boolean;
  isConcurrent?: boolean;
}

export function calculateTotalPrice(
  cart: IBookAppointmentFull,
  addOnsWithCompleteData: TService[]
): number {
  return cart.services.reduce(
    (acc: number, serviceFull: IBookAppointmentServiceFull) =>
      acc +
      serviceFull.service.price +
      (serviceFull?.addOns?.reduce(
        (curr: number, addOnId: string) =>
          curr +
          (addOnsWithCompleteData?.find((item) => item.id === addOnId)?.price ||
            0),
        0
      ) || 0),
    0
  );
}

export function isSelectedService(
  cart: IBookAppointmentFull,
  _serviceId: string,
  _personIndex: number
): boolean {
  return !!cart.services?.find((service: IBookAppointmentServiceFull) => {
    return (
      service.personIndex === _personIndex && service.service.id === _serviceId
    );
  });
}

export function getAndRemoveArtistByPersonIndex(
  artists: TPossibleAppointmentArtist[],
  service: IBookAppointmentServiceFull
) {
  const index = artists.findIndex(
    (artist: any) =>
      artist.personIndex === service.personIndex &&
      artist.service.id === service.service.id
  );

  if (index !== -1) {
    return artists.splice(index, 1)[0]; // Remove the element at the found index and return it
  }

  return null;
}

export function removeArtistById(
  artists: TPossibleAppointmentArtist[],
  id?: string
) {
  for (let i = 0; i < artists.length; i++) {
    if (artists[i].artist_id === id) {
      artists.splice(i, 1);
    }
  }
}
