import "./ConcurrentConfirmationModal.scss";
import Modal from "../modal/Modal";
import { TSlidingModalAlign } from "../sliding-modal/SlidingModal";
import { Button, IButtonProps } from "../../../elements/button/Button";
import { COLORS } from "../../../models/colors";
import { VARIANTS } from "../../../models/variants";
import { bemElement } from "../../../utils/bem-class-names";
import { joinClassNames } from "../../../utils/join-class-names";

const baseClassName = "confirmation-modal";
const bem = bemElement(baseClassName);

interface IConfirmationModalData {
  title?: string;
  text?: string;
  show: boolean;
  onHide: () => void;
  disableHeader?: boolean;
  disableBackground?: boolean;
  slideTo?: number;
  align?: TSlidingModalAlign;
  rightButton?: IButtonProps;
  onConfirm?: () => void;
  onReject?: () => void;
  className?: string;
  cyId?: string;
}

const ConcurrentConfirmationModal = ({
  title,
  text = "Are you sure?",
  show,
  onHide,
  disableHeader,
  disableBackground,
  slideTo,
  align = "bottom",
  rightButton,
  onConfirm,
  onReject,
  className = "",
  cyId = ""
}: IConfirmationModalData) => {
  const BottomControls = () => {
    return (
      <div className={bem("buttons-wrapper")}>
        <Button
          color={COLORS.SUCCESS}
          variant={VARIANTS.FILLED}
          text="No, thanks"
          onClick={onReject}
          className="flexible flex-2"
        />
        <Button
          color={COLORS.SUCCESS}
          variant={VARIANTS.FILLED}
          text="Yes"
          onClick={onConfirm}
          className="flexible flex-2"
        />
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={title}
      disableHeader={disableHeader}
      disableBackground={disableBackground}
      slideTo={slideTo}
      align={align}
      rightButton={rightButton}
      BottomControls={BottomControls}
      className={joinClassNames(baseClassName, className)}
      backgroundClassName={bem("background")}
    >
      <div className={bem("text")}>{text}</div>
    </Modal>
  );
};

export default ConcurrentConfirmationModal;
